<template>
  <!-- 搜索 左侧 -->
  <div>
    <div class="Search">
      <input type="text" class="ipt" @keyup.enter="SearchGo" v-model="iptval">
      <img class="guanbi" src="@/assets/img/guanbi_icon.png" v-if="isshow==true" @click="guanbix" alt="">
      <img class="sousuo" src="@/assets/img/search_icon.png" @click="SearchGo" alt="">
    </div>
    <div class="ipts_border"></div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="投诉" name="first">
        <div>含“<span style="color:red">{{iptval}}</span>”的投诉有{{Nums}}条</div>
        <div class="Hot">
          <van-list v-model="loading1" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished1" finished-text="没有更多了" @load="scroll">
            <div class="Hot_List" v-for="(item, index) in List" :key="index" @click="Go_xq(item.id)">
              <div class="text Hot_List_top">
                <!-- 搜索关键字高亮 -->
                <span>{{item.statement_title.slice(0,item.statement_title.toLowerCase().indexOf(iptval.toLowerCase()))}}</span><span
                  style="color:red">{{item.statement_title.slice(item.statement_title.toLowerCase().indexOf(iptval.toLowerCase()),item.statement_title.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span><span>{{item.statement_title.substr(item.statement_title.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span>
              </div>
              <div class="Hot_List_content">
                <div class="Hot_List_content_top">
                  <span>[投诉对象]</span>
                  <span>{{ item.businezss_name }}</span>
                </div>
                <div class="Hot_List_content_top">
                  <span>[投诉理由]</span>
                  <span>{{ item.complaint_reason }}</span>
                </div>
              </div>
              <div class="Hot_List_bottom">
                <div class="Hot_List_bottom_left">
                  <div>
                    <img src="@/assets/img/baoguang_icon.png" alt="">
                    <span>1</span>
                  </div>
                  <div>
                    <img src="@/assets/img/dianzan_icon.png" alt="">
                    <span>{{ item.like_num }}</span>
                  </div>
                </div>
                <div class="Hot_List_bottom_right">
                  <div v-if="item.status == 2" class="is_Hot">已完成</div>
                  <div v-if="item.status == 1" class="is_Hot1">处理中</div>
                </div>
              </div>
              <div class="Hot_bottom">
                <div class="Hot_bottom_left">
                  <img :src="item.headimgurl" alt="">
                  <span>{{ item.nickname }}</span>
                </div>
                <div class="Hot_bottom_right">
                  {{item.create_time.slice(0,10)}}
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </el-tab-pane>
      <el-tab-pane label="品牌" name="second">
        <div>含“<span style="color:red">{{iptval}}</span>”的品牌有{{Nums1}}条</div>
        <div class="Brands">
          <van-list v-model="loading" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished" finished-text="没有更多了" @load="scrolls">
            <div class="Brands_list" v-for="(item,index) in List1" :key="index" @click="G_RelatedComplaints(item.id)">
              <div class="Brands_list_top">
                <div class="Brands_list_top_left">
                  <img
                    :src="item.image!=''?item.image:'https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2165691866,2570924737&fm=26&gp=0.jpg'"
                    alt="">
                  <div class="y_renling" v-if="item.is_claim ==1">商</div>
                </div>
                <div class="Brands_list_top_right">
                  <div class="Brands_list_top_right_t">
                    <div class="name">
                      <span>{{item.name.slice(0,item.name.toLowerCase().indexOf(iptval.toLowerCase()))}}</span><span
                        style="color:red">{{item.name.slice(item.name.toLowerCase().indexOf(iptval.toLowerCase()),item.name.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span><span>{{item.name.substr(item.name.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span>
                    </div>
                    <div class="renling" v-if="item.is_claim ==0" @click.stop="Go_claim(item)">
                      <img src="@/assets/img/renling_icon.png" alt="">
                      <span>去认领</span>
                    </div>
                  </div>
                  <div class="Brands_list_top_right_b">
                    <div class="pingxing" v-if="item.efficiency_results_level== 5">
                      <span>效率</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-if="item.efficiency_results_level== 4">
                      <span>效率</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-if="item.efficiency_results_level== 3">
                      <span>效率</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-if="item.efficiency_results_level== 2">
                      <span>效率</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-if="item.efficiency_results_level== 1">
                      <span>效率</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>

                    <div class="pingxing" v-if="item.service_level== 5">
                      <span>满意度</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-else-if="item.service_level== 4">
                      <span>满意度</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-else-if="item.service_level== 3">
                      <span>满意度</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-else-if="item.service_level== 2">
                      <span>满意度</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                    <div class="pingxing" v-else>
                      <span>满意度</span>
                      <img src="@/assets/img/xingxing.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="Brands_list_bottom">
                <div class="Brands_list_bottom_L">
                  <div class="num">{{item.complaint_count}}</div>
                  <div class="nums">总投诉</div>
                </div>
                <div class="Brands_list_bottom_L">
                  <div class="num">{{item.complete_count}}</div>
                  <div class="nums">已完成</div>
                </div>
                <div class="Brands_list_bottom_L">
                  <div class="num">{{item.unfinished}}</div>
                  <div class="nums">未完成</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </el-tab-pane>
      <el-tab-pane label="资讯" name="third">
        <div>含“<span style="color:red">{{iptval}}</span>”的资讯有{{Nums2}}条</div>
        <div>
          <van-list v-model="loading2" style="display:flex;flex-wrap: wrap;justify-content: space-between;"
            :finished="finished2" finished-text="没有更多了" @load="scrolls1">
            <div class="Hot1" v-for="(item, index) in List2" :key="index">
              <a :href="item.url" class="Hot_a1" target="_blank">
                <div class="Hot_left1">
                  <img :src="item.img" v-if="item.img != ''" alt="" />
                  <img src="https://images.qudonghao.cn//uploads/20210425/b16d16ccf672077c53ef60445a04c4ec.jpg"
                    v-if="item.img == ''" alt="" />
                </div>
                <div class="Hot_right1">
                  <div class="Hot_right_top1">
                     <span>{{item.title.slice(0,item.title.toLowerCase().indexOf(iptval.toLowerCase()))}}</span><span
                  style="color:red">{{item.title.slice(item.title.toLowerCase().indexOf(iptval.toLowerCase()),item.title.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span><span>{{item.title.substr(item.title.toLowerCase().indexOf(iptval.toLowerCase())+iptval.length)}}</span>
                  </div>
                  <div class="Hot_right_bottom1">
                    <div class="Hot_right_bottom_left1">
                      <img v-if="item.user_headimgurl != ''" :src="item.user_headimgurl" alt="" />
                      <img v-if="item.user_headimgurl == ''" src="http://www.qudonghao.cn/static/headerimg.png"
                        alt="" />

                      <div class="name1" style="margin-right:15px;">
                        <span>{{item.user_nickname}}
                        </span>
                        </div>
                      <div class="data1">{{ item.published }}</div>
                    </div>
                    <div class="Hot_right_bottom_right1">
                      <div class="Hot_right_bottom_right_num1">
                        <img src="../assets/img/dianzan_icon.png" alt="" />
                        <span>{{ item.b }}</span>
                      </div>
                      <div class="Hot_right_bottom_right_num1">
                        <img src="../assets/img/pinglun_icon.png" alt="" />
                        <span>{{ item.comments }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- <van-pagination v-model="currentPage" @change="Onswitch" :total-items="24" :items-per-page="5" /> -->
          </van-list>
        </div>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Axios from "axios";
import md5 from "js-md5";
export default {
  inject: ["reload"],
  data() {
    return {
      List: [],
      List1: [],
      List2: [],
      activeName: "first",
      currentPage: 1,
      iptval: "", //上个页面带过来的值
      Nums: "", //数量
      Nums1: "",
      Nums2: "",
      isshow: true,
      load: true,
      page: 2,
      loading: false,
      finished: false,
      page1: 2,
      loading1: false,
      finished1: false,
      page2: 2,
      loading2: false,
      finished2: false,
    };
  },
  // watch: {
  //   iptval(val) {
  //     console.log(val);
  //     let query = this.$router.history.current.query;
  //     let path = this.$router.history.current.path;
  //     console.log(query);
  //     console.log(path);
  //     console.log(1111)
  //     // 对象的拷贝
  //     let newQuery = JSON.parse(JSON.stringify(query));
  //     // 地址栏的参数值赋值
  //     newQuery.iptval = val;
  //     // //console.log(newQuery.iptval);
  //     // //console.log(query);
  //     if (newQuery.iptval == query) {
  //       this.$router.push({ path, query: newQuery });
  //     }
  //   },
  // },
  methods: {
    // 跳转认领页面
    Go_claim(OG_count) {
      let G_count = JSON.stringify(OG_count);
      let tokens = localStorage.getItem("token");
      if(tokens){
        this.$router.push({
        name: "Entry_Enterprises",
        query: { G_count: G_count },
      });
      }else{
          this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {         
        });
      }
    },
    // 跳转详情页面
    Go_xq(id) {
      //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
    guanbix() {
      this.isshow = false;
    },
    SearchGo() {
      // //console.log(this.iptval);
      // this.$router.push({path: '/Search',query:{ iptval:this.iptsearch}});
      var that = this;
      that.S_bland();
      that.complaint_index();
      that.request();
    },
    handleClick(tab, event) {
      // //console.log(tab, event);
    },
    complaint_index() {
      var that = this;
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/complaint_index",
        params: {
          type: 0,
          title: that.iptval,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        // //console.log(res.data.data);
        this.Nums = res.data.data.total;
        this.List = res.data.data.data;
      });
    },
    //投诉分页
    scroll() {
      var that = this;
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/complaint_index",
        params: {
          type: 0,
          title: that.iptval,
          page: this.page1,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        setTimeout(() => {
          this.page1 = this.page1 + 1;
          if (res.data.data.data.length > 0) {
            this.List.push(...res.data.data.data);
            this.loading1 = false;
          } else {
            this.finished1 = true;
          }
        }, 500);
      });
    },
    S_bland() {
      let that = this;
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/manufacturer_list_one",
        params: {
          name: that.iptval,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        // //console.log(res);
        that.List1 = res.data.data.data;
        this.Nums1 = res.data.data.total;
      });
    },
    //品牌分页
    scrolls() {
      var that = this;
      Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/manufacturer_list_one",
        params: {
          name: that.iptval,
          page: this.page,
          key: md5("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB"),
        },
      }).then((res) => {
        setTimeout(() => {
          this.page = this.page + 1;
          if (res.data.data.data.length > 0) {
            this.List1.push(...res.data.data.data);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }, 500);
      });
    },
    // 跳转品牌详情
    G_RelatedComplaints(id) {
      this.$router.push({
        name: "BrandHome",
        params: { id: id },
      });
    },
    request() {
      let that = this;
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/index/index",
        params: {
          // category: 823,
          title: that.iptval,
        },
      }).then((res) => {
        //console.log(res);
        that.List2 = res.data.data;
        this.Nums2 = res.data.count;
      });
    },
    //咨询分页
    scrolls1() {
      var that = this;
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/index/index",
        params: {
          // category: 823,
          page: this.page2,
          title: that.iptval,
        },
      }).then((res) => {
        //console.log(res);
        setTimeout(() => {
          this.page2 = this.page2 + 1;
          if (res.data.data.length > 0) {
            this.List2.push(...res.data.data);
            this.loading2 = false;
          } else {
            this.finished2 = true;
          }
        }, 500);
      });
    },
  },
  created() {
    var that = this;
    that.iptval = that.$route.query.iptval;
    that.complaint_index();
    that.S_bland();
    that.request();
  },
};
</script>

<style scoped>
.ipts_border {
  width: 900px;
  border-bottom: 2px solid #999999;
  margin-bottom: 25px;
}
.Search {
  width: 860px;
  margin-bottom: 10px;
  position: relative;
}
.Search .ipt {
  width: 900px;
  border: none;
  height: 40px;
  background: #f5f5f5;
  font-size: 32px;
}
.guanbi {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.sousuo {
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
>>> .el-tabs__item {
  font-size: 20px !important;
}
>>> .el-tabs__active-bar {
  background: none;
}
/* 投诉 */
.Hot {
  margin-top: 20px;
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Hot_List {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.Hot_List_top {
  margin-bottom: 15px;
}
.Hot_List_content_top span:nth-child(1) {
  color: #1f72d9;
}
.Hot_List_content_top span:nth-child(2) {
  color: #666666;
}
.Hot_List_bottom {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}
.Hot_List_content_top {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_List_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_List_bottom_left span {
  color: #999999;
  font-size: 14px;
}

.Hot_List_bottom_left div {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.Hot_List_bottom_left div:nth-child(1) img {
  width: 24px;
  height: 14px;
  margin-right: 5px;
}
.Hot_List_bottom_left div:nth-child(2) img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.is_Hot {
  background: #d2e3f7;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #1f72d9;
  font-weight: bold;
}
.is_Hot1 {
  background: #ffe9d3;
  opacity: 0.7;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fea962;
  font-weight: bold;
}
.Hot_bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hot_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_bottom_left img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
.Hot_bottom_right {
  font-size: 14px;
  color: #999999;
}
/* 品牌 */
.Brands {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Brands_list {
  width: 400px;
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.Brands_list_top {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}

.Brands_list_top_left img {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  margin-right: 30px;
}

.Brands_list_top_left {
  position: relative;
}

.y_renling {
  width: 28px;
  height: 28px;
  background: #1f72d9;
  text-align: center;
  line-height: 28px;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 35px;
  bottom: 3px;
}

.renling {
  width: 96px;
  height: 30px;
  background: #fe9025;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.renling img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 6px;
}

.renling span {
  color: #ffffff;
}

.Brands_list_top_right_t {
  display: flex;
  align-items: center;
}

.Brands_list_top_right_t .name {
  width: 156px;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pingxing {
  display: flex;
  align-items: center;
}

.pingxing span {
  width: 50px;
  text-align: right;
  color: #666666;
  margin-right: 15px;
}

.pingxing img {
  width: 17px;
  height: 16px;
  margin-right: 6px;
}

.Brands_list_top_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Brands_list_bottom {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 20px;
}

.Brands_list_bottom_L:nth-child(1) {
  border-right: 1px solid #e8e8e8;
  margin-left: 20px;
  padding-right: 40px;
}

.Brands_list_bottom_L:nth-child(2) {
  border-right: 1px solid #e8e8e8;
  padding-right: 40px;
}

.Brands_list_bottom_L:nth-child(3) {
  margin-right: 20px;
}

.Brands_list_bottom_L .num {
  font-size: 24px;
  font-weight: bold;
  color: #1f72d9;
}

.Brands_list_bottom_L .nums {
  font-size: 15px;
  font-weight: 500;
  color: #666666;
}
/* 资讯 */
.Hot1 {
  width: 860px;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  cursor: pointer;
  margin-top: 20px;
}
.Hot_a1 {
  display: flex;
}
.Hot_left1 img {
  width: 215px;
  height: 145px;
  border-radius: 8px;
  object-fit: cover;
}
.Hot_right1 {
  width: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Hot_right_top1 {
  margin-top: 10px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_right_bottom1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Hot_right_bottom_left1 {
  display: flex;
  align-items: center;
}
.Hot_right_bottom_left1 img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.Hot_right_bottom_left1 .name {
  font-size: 14px;
  margin-right: 25px;
}
.Hot_right_bottom_left1 .data {
  font-size: 13px;
  color: #999999;
}
.Hot_right_bottom_right1 {
  display: flex;
}
.Hot_right_bottom_right_num1 {
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.Hot_right_bottom_right_num1 img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.Hot_right_bottom_right_num1 span {
  color: #999999;
}
>>> .el-tabs__nav-wrap::after {
  display: none;
}
>>> .van-list__finished-text {
  width: 100%;
}
>>> .van-list__loading {
  width: 100%;
}
</style>