<template>
  <div class="BJS">
    <Header></Header>
        <!-- 左右两侧 -->
    <div class="Bottom_list">
      <div class="Bottom_list_bx">
        <SearchLeft></SearchLeft>
          <ReportRight></ReportRight>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Axios from "axios";
// import { banner } from "../request/api";
import SearchLeft from "../components/SearchLeft.vue";
import ReportRight from "../components/ReportRight.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Search",
  data() {
    return {
      arr: [],
      isiOS: "", //初始一个空值
      isAndroid: "", //初始一个空值
      text: "过滤器",
    };
  },

  components: {
    Header,
    SearchLeft,
    ReportRight,
    Footer
  },
  computed: {},
  methods: {
    // request() {
    //   let that = this;
    //   // var param=new URLSearchParams;
    //   // param.append("cid","65816");
    //   // 这里要删除请求头
    //   delete Axios.defaults.headers.post;
    //   Axios({
    //     method: "post",
    //     url: "https://weixin.qudong.com/api/index/index",  
    //        param: {
    //     category:823
    //     },
    //   }).then((res) => {
    //     //console.log(res);
    //     that.arr = res;
    //   });
    // },
  },
  mounted: function () {
    // this.request();
  },
};
</script>


<style scoped>
html,
body {
  background: #f5f5f5;
}
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
.logo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
}
.logo img {
  width: 110px;
  height: 30px;
}
.logo button {
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background: #179afe;
  color: white;
  border: none;
  outline: none;
}
.Bottom_list {
  width: 100%;
}
.Bottom_list_bx {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  /* background: red; */
}
</style>
